import React from "react"
import ServiceArea from "./ServiceArea"
const ServiceAreaList = ({ serviceAreas, enabled, slug }) => {
  if (enabled === "kontent_item_service_area_group") {
    return (
      <>
        {serviceAreas.value.map(({ elements, id }) => {
          const { heading, sub_heading, service_areas } = elements
          return (
            <section className="container locations-list" key={id}>
              {heading.value !== "" && <h2>{heading.value}</h2>}
              {sub_heading.value !== "" && <h3>{sub_heading.value}</h3>}
              <ul>
                {service_areas.value.map(({ elements, id }) => {
                  return (
                    <ServiceArea
                      key={id}
                      serviceArea={elements}
                      parentSlug={slug}
                    />
                  )
                })}
              </ul>
            </section>
          )
        })}
      </>
    )
  } else {
    return null
  }
}
export default ServiceAreaList
